window.Client = {
    init: function() {
        const url = new URL(window.location.href);
        if (!url.searchParams.has("deal") || !url.searchParams.get("deal")) {
            const mainWrapper = document.createElement("div");
            mainWrapper.style.display = "flex";
            mainWrapper.style.flexDirection = "column";
            mainWrapper.style.justifyContent = "center";
            mainWrapper.style.alignItems = "center";

            const logoElem = document.createElement("img");
            logoElem.style.height = "100px";
            logoElem.src = "https://assets.prd.heyflow.com/flows/provinzial-service-solar-privat-fragebogen/www/assets/a7f49cb7-8e7b-49d3-abd2-ca1ec1f7a0d8.svg";
            mainWrapper.appendChild(logoElem);

            const headerElem = document.createElement("h2");
            headerElem.innerText = "Ungültiger Link";
            headerElem.style.marginTop = "60px";
            mainWrapper.appendChild(headerElem);

            const textWrapper = document.createElement("div");
            textWrapper.style.marginTop = "30px";
            textWrapper.style.textAlign = "center";
            const textElem1 = document.createElement("h4");
            textElem1.innerText = "Der aufgerufene Link ist leider ungültig, da er keine Deal ID enthält.";
            const textElem2 = document.createElement("h4");
            textElem2.innerText = "Bitte vergewissern Sie sich, dass Sie den vollständigen Link aus der E-Mail verwendet haben.";
            textWrapper.appendChild(textElem1).appendChild(textElem2);
            mainWrapper.appendChild(textWrapper);

            const bodyElement = document.querySelector("body");
            document.querySelector(".main").remove();
            bodyElement.appendChild(mainWrapper);
        }
    },
}